<template>
  <div class="user page">
    <Nav></Nav>
    <div class="wrap" style="padding-top: 10px; min-height: 700px">
      <a-layout>
        <a-layout-sider>
          <Menu></Menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-row type="flex" justify="center" align="middle" class="top">
              <a-col :span="24">
                <div style="width:90%;display: flex;justify-content: center;align-items: center;"><icon-font type="icon-bonuspoints" /> 地址簿</div>
                <div style="display: flex;justify-content: center;align-items: center;" @click="addAddress">
                  <a-icon type="plus-circle" /> 添加
                </div>
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content>
            <div :style="{ minHeight: '410px' }">
              <a-list
                :loading="loading"
                item-layout="horizontal"
                :data-source="data"
              >
                <div
                  v-if="showLoadingMore"
                  slot="loadMore"
                  :style="{
                    textAlign: 'center',
                    marginTop: '12px',
                    height: '32px',
                    lineHeight: '32px',
                  }"
                >
                  <a-spin v-if="loadingMore" />
                  <a-button v-else @click="onLoadMore"> loading more </a-button>
                </div>
                <a-list-item slot="renderItem" slot-scope="item, index">
                  <a
                    v-if="op && op.length > 0"
                    slot="actions"
                    @click="selectAdd(index)"
                    >使用此地址</a
                  >
                  <a slot="actions" @click="setedit(item.id)">编辑</a>
                  <a slot="actions" @click="setdelete(item.id)">删除</a>
                  <a slot="actions" @click="setdefault(item.id)">设为默认</a>
                  <a-list-item-meta
                    :description="item.areaInfo + '  ' + item.address"
                  >
                    <a slot="title">{{ item.name }}</a>
                  </a-list-item-meta>
                  <div>是否默认：{{ item.isDefault == 1 ? "是" : "否" }}</div>
                </a-list-item>
              </a-list>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";
import Menu from "../user/components/menu";
import { Icon } from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2747721_gimu9jlw02u.js",
});
export default {
  components: {
    Nav,
    Foot,
    Menu,
    IconFont,
  },
  data() {
    return {
      user: {},
      data: [],
      loading: true,
      loadingMore: false,
      showLoadingMore: true,
      PageSize: 10,
      pageIndex: 1,
      op: "",
    };
  },
  computed: {},
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  mounted() {
    this.user = this.storage.getItem("user");
    if (!this.user) {
      this.getUserInfo();
    }
    let geturl = window.location.href;
    if (geturl.indexOf("?") != -1) {
      let getqyinfo = geturl.split("?")[1];
      let getqys = getqyinfo.split("&");
      let obj = {}; //创建空对象，接收截取的参数
      for (let i = 0; i < getqys.length; i++) {
        let item = getqys[i].split("=");
        let key = item[0];
        let value = item[1];
        obj[key] = value;
      }
      this.op = obj.op;
    }
    this.getData((res) => {
      this.loading = false;
      this.data = res;
    });
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.storage.setItem("user", res);
          this.user = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData(callback) {
      this.api
        .userAddressList({
          pageIndex: this.pageIndex - 1,
          pageSize: this.PageSize,
        })
        .then((res) => {
          callback(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onLoadMore() {
      this.loadingMore = true;
      this.pageIndex += 1;
      this.getData((res) => {
        this.data = this.data.concat(res);
        this.loadingMore = false;

        this.$nextTick(() => {
          window.dispatchEvent(new Event("resize"));
        });
      });
    },
    addAddress() {
      this.$router.push("/user/addressbookAdd?id=0&op=" + this.op);
    },
    setedit(id) {
      this.$router.push("/user/addressbookAdd?id=" + id + "&op=" + this.op);
    },
    setdelete(id) {
      var that = this;
      this.$confirm({
        title: "提示",
        content: "确认删除?",
        onOk() {
          that.api
            .deleteAddress({
              id: id,
            })
            .then(() => {
              that.$message.success("删除成功", 10);
              that.getData((res) => {
                that.loading = false;
                that.data = res;
              });
            })
            .catch((err) => {
              console.log(err);
            });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    setdefault(id) {
      this.api
        .setAddressDefault({
          id: id,
        })
        .then(() => {
          this.$message.success("设置成功", 10);
          this.getData((res) => {
            this.loading = false;
            this.data = res;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectAdd(index) {
      if (this.op == "send") {
        let Waybill = this.storage.getItem("Waybill");
        if (!Waybill) {
          Waybill = {};
        }
        Waybill.send = this.data[index];
        this.storage.setItem("Waybill", Waybill);
        this.$router.push("/post?op=" + this.op);
      } else if (this.op == "receive") {
        let Waybill = this.storage.getItem("Waybill");
        if (!Waybill) {
          Waybill = {};
        }
        Waybill.receive = this.data[index];
        this.storage.setItem("Waybill", Waybill);
        this.$router.push("/post?op=" + this.op);
      } else if (this.op == "orderReceive") {
        let order = this.storage.getItem("order");
        if (!order) {
          order = {};
        }
        order.receive = this.data[index];
        this.storage.setItem("order", order);
        this.$router.push("/Buy?op=" + this.op);
      }
    },
  },
};
</script>


<style lang="less">
@import "~ant-design-vue/dist/antd.less";

.page {
  background: #f5f5f5;
}

.wrap {
  height: 100%;

  // padding-bottom: 20px;
}

.ant-layout {
  height: 100%;
}

.ant-layout-sider,
.ant-layout-content,
.ant-layout-header,
.ant-layout-footer {
  background: #ffffff !important;
  padding: 10px;
}

.ant-layout-sider {
  margin-right: 10px;
  text-align: center;
}

.ant-layout-content {
  margin-top: 10px;
}

.ant-layout-header {
  height: auto;

  .top {
    .ant-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .anticon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.user {
  .user-name {
    font-size: 14px;
    margin-top: 10px;
  }

  .other-info {
    text-align: left;
    margin-left: 30px;

    span {
      color: @text-color-secondary;
    }
  }
}

.menu {
  text-align: left;
  padding-left: 30px;
  line-height: 28px;
  margin-top: 30px;
  border-top: 1px solid #e2e2e2;

  dl {
    padding-top: 10px;

    dt {
      font-size: 16px;
      line-height: 28px;
    }

    dd {
      color: @text-color-secondary;
    }
  }
}

div.content-wrap {
  padding: 15px;
}

.num {
  color: red;
  padding-right: 10px;
}
</style>